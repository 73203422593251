<template>
    <div>
        <v-card>
            <v-card-actions class="mx-3">
                <v-spacer></v-spacer>
                <v-btn class="mr-3 mb-3 white--text" small color="green" depressed @click="export2xls">Export to XLS</v-btn>
            </v-card-actions>
            <v-card-subtitle>
                <div style="text-align:center; font-weight:bold; font-size:16px; padding:10px">Hitungan Cost</div>
                <v-row class="compact-form my-2">
                    <v-col cols="1" class="pt-5">
                        Tanggal
                    </v-col>
                    <v-col cols="3" class="">
                        <v-menu v-model="dateMenu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="reportDate" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details="auto" dense outlined></v-text-field>
                            </template>
                            <v-date-picker v-model="reportDate" :allowed-dates="allowedDates"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="2">
                        <v-btn depressed color="primary" @click="runReport">Run</v-btn>
                    </v-col>
                </v-row>                

            </v-card-subtitle>

            <v-card-text class="ml-3 mr-3">

                <!-- <div id="printresep" style="overflow:auto"> -->

                    <v-tabs v-model="tab" align-with-title>
                        <v-tab>Budget</v-tab>
                        <v-tab>Cost</v-tab>
                        <v-tab>Packaging</v-tab>
                        <v-tab>Summary</v-tab>
                        <v-tab>Stock Worth (RM)</v-tab>
                        <v-tab>Stock Worth (LB)</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab" align-with-title>
                        <v-tab-item>
                            <v-simple-table fixed-header height="400px" id="variantTbl" ref="tableBOM">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th><b>Volume</b></th>
                                            <th><b>Kode</b></th>
                                            <th><b>Produk</b></th>
                                            <th><b>Botol Produksi</b></th>
                                            <th><b>Budget Per Botol</b></th>
                                            <th><b>Total Budget</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(obj, idx) in budgets" :key="idx">
                                            <td>{{obj.size}}</td>
                                            <td>{{obj.code}}</td>
                                            <td>{{obj.name}}</td>
                                            <td>{{obj.qty}}</td>
                                            <td>{{obj.budget | rounded | numbers}}</td>
                                            <td>{{obj.total_budget | rounded | numbers}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="5"></td>
                                            <td>{{getTotalBudget | rounded | numbers}}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-tab-item>

                        <v-tab-item>
                            <v-simple-table fixed-header height="400px" id="variantTbl" ref="tableBOM">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th><b>Kode</b></th>
                                            <th><b>Nama Bahan</b></th>
                                            <th style="text-align: center"><b>Pemakaian (Kg)</b></th>
                                            <th style="text-align: center"><b>Deviasi (Kg)</b></th>
                                            <th style="text-align: center"><b>Harga Per Kg (Kg)</b></th>
                                            <th style="text-align: center"><b>Total Pemakaian (Rp)</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(obj, idx) in printObj" :key="idx">
                                            <td>{{obj.code}}</td>
                                            <td>{{obj.description}}</td>
                                            <td>{{obj.weighing_actual | rounded2}}</td>
                                            <td>{{obj.deviasi*-1 | rounded2}}</td>
                                            <td>{{obj.unit_cost | rounded | numbers}}</td>
                                            <td>{{(Number(obj.weighing_actual)-Number(obj.deviasi))*Number(obj.unit_cost)| rounded | numbers}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="5"></td>
                                            <td>{{getTotalCost | rounded | numbers}}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-tab-item>

                        <v-tab-item>
                            <v-simple-table fixed-header height="400px" id="variantTbl" ref="tableBOM">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th><b>Packaging</b></th>
                                            <th><b>Botol Produksi</b></th>
                                            <th><b>Budget Per Botol</b></th>
                                            <th><b>Total Budget</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(obj, idx) in packagings" :key="idx">
                                            <td>{{obj.size}} ml</td>
                                            <td>{{obj.qty}}</td>
                                            <td>{{obj.budget | rounded | numbers}}</td>
                                            <td>{{obj.total_budget | rounded | numbers}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3"></td>
                                            <td>{{getTotalBudgetPack | rounded | numbers}}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-tab-item>

                        <v-tab-item>
                            <v-simple-table height="400px" id="variantTbl" ref="tableBOM">
                                <template v-slot:default>
                                    <tbody>
                                        <tr>
                                            <td>Total Budget</td>
                                            <td class="font-weight-bold">{{getTotalBudget | rounded | numbers}}</td>
                                        </tr>
                                        <tr>
                                            <td>Production Cost</td>
                                            <td class="font-weight-bold">{{getTotalCost | rounded | numbers}}</td>
                                        </tr>
                                        <tr>
                                            <td>Packaging</td>
                                            <td class="font-weight-bold">{{getTotalBudgetPack | rounded | numbers}}</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td class="font-weight-bold">{{(getTotalBudgetPack+getTotalCost) - getTotalBudget  | rounded | numbers}}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>                        
                        </v-tab-item>

                        <!-- Stock Worth RM -->
                        <v-tab-item>
                            <v-simple-table fixed-header height="400px" id="variantTbl" ref="tableBOM">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th><b>Kode</b></th>
                                            <th><b>Jenis Bahan</b></th>
                                            <th><b>Sisa Stok - {{addDate(6) | moment}}</b></th>
                                            <th><b>Harga Per Kg</b></th>
                                            <th><b>Value</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(obj, idx) in stockworthRM" :key="idx">
                                            <td>{{obj.code}}</td>
                                            <td>{{obj.description}}</td>
                                            <td>{{obj.qty_count | rounded2 | numbers}}</td>
                                            <td>{{obj.unit_cost | rounded | numbers}}</td>
                                            <td>{{Number(obj.qty_count)*Number(obj.unit_cost) | rounded | numbers}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4"></td>
                                            <td>{{getTotalStockWorthRM | rounded | numbers}}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-tab-item>    

                        <!-- Stock Worth LB -->
                        <v-tab-item>
                            <v-simple-table fixed-header height="400px" id="variantTbl" ref="tableBOM">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th><b>Kode</b></th>
                                            <th><b>Jenis Bahan</b></th>
                                            <th><b>Sisa Stok - {{addDate(6) | moment}}</b></th>
                                            <th><b>Harga</b></th>
                                            <th><b>Value</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(obj, idx) in stockworthLB" :key="idx">
                                            <td>{{obj.code}}</td>
                                            <td>{{obj.description}}</td>
                                            <td>{{obj.qty_count | rounded2 | numbers}}</td>
                                            <td>{{obj.unit_cost | rounded | numbers}}</td>
                                            <td>{{Number(obj.qty_count)*Number(obj.unit_cost) | rounded | numbers}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="4"></td>
                                            <td>{{getTotalStockWorthLB | rounded | numbers}}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-tab-item>                        
                    </v-tabs-items>

                <!-- </div> -->
            </v-card-text>
        </v-card>
        <v-overlay :value="onLoad" z-index="999">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>        

    </div>
</template>

<script>
import moment from 'moment'
import { utils, writeFileXLSX } from 'xlsx';

export default {

    data: () => ({
        dialog: false,
        onLoad: false,
        printObj: [],
        dateMenu: false,
        reportDate: null,
        reportCat: 'RAW',
        allowedDates: val => new Date(val+' 00:00:00').getDay() === 5,
        categories: [
            {code:'RAW', desc: 'Bahan Baku'},
            {code:'LNB', desc: 'Botol, Tutup, Label'}
        ],
        budgets: [],
        packagings: [],
        stockworthRM: [],
        stockworthLB: [],
        tab: null,
    }),

    computed: {
      miscCodes() {
        return this.$store.state.misccode.objs
      },
      getTotalCost() {
        return this.printObj.reduce((accumulator, obj) => {
            return accumulator + ((Number(obj.weighing_actual)+Number(obj.deviasi))*Number(obj.unit_cost));
        }, 0);
      },
      getTotalBudget() {
        return this.budgets.reduce((accumulator, obj) => {
            return accumulator + Number(obj.total_budget);
        }, 0);
      },
      getTotalBudgetPack() {
        return this.packagings.reduce((accumulator, obj) => {
            return accumulator + Number(obj.total_budget);
        }, 0);
      },
      getTotalStockWorthRM() {
        return this.stockworthRM.reduce((accumulator, obj) => {
            return accumulator + (Number(obj.qty_count)*Number(obj.unit_cost));
        }, 0);
      },
      getTotalStockWorthLB() {
        return this.stockworthLB.reduce((accumulator, obj) => {
            return accumulator + (Number(obj.qty_count)*Number(obj.unit_cost));
        }, 0);
      }
    },

    watch: {
    },

    filters: {
        moment: function (date) {
            return moment(date).format('ddd D MMM YYYY');
        },
    },

    async created() {
    },

    methods: {
        async runReport() {
            this.onLoad = true
            this.printObj = (await this.$store.dispatch('getObjs',{apidomain:'others/rptcost/' + this.reportDate, parameter:''})).data
            this.budgets = (await this.$store.dispatch('getObjs',{apidomain:'others/budgetbotol/' + this.reportDate, parameter:''})).data
            this.packagings = (await this.$store.dispatch('getObjs',{apidomain:'others/budgetpackaging/' + this.reportDate, parameter:''})).data
            this.stockworthRM = (await this.$store.dispatch('getObjs',{apidomain:'others/stockworth/' + this.reportDate + '/RM', parameter:''})).data
            this.stockworthLB = (await this.$store.dispatch('getObjs',{apidomain:'others/stockworth/' + this.reportDate + '/LB', parameter:''})).data
            this.onLoad = false
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        export2xls() {
            console.log(this.$refs.tableBOM.$el.firstChild.firstChild)
            const wb = utils.table_to_book(this.$refs.tableBOM.$el.firstChild.firstChild)
            writeFileXLSX(wb, "Cost.xlsx")

        },
        addDate(nday){
            const dt = new Date(this.reportDate)
            dt.setDate(dt.getDate()+ nday)
            return dt
        },
    }    
}
</script>

<style scoped>
table, th, td {
  border: 1px solid grey;
  border-collapse: collapse;
}
#printresep th, td {
  padding: 10px;
  font-size: 12px;
}
.box {
    border:1px solid grey;
    height:30px;
    width:100px;    
}
</style>